import Image from 'next/image';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IconPause } from '@/components/icon/IconPause';
import { IconPlay } from '@/components/icon/IconPlay';
import Theme from '../../../styles/Theme';
import { PlayerStore } from '../../../data/PlayerStore';
import { ClassicCapsule } from '../../../models/ClassicCapsule';
import { IsPlayedCover } from '../IsPlayedCover';

interface Props {
	experienceSlug: string;
	capsule: ClassicCapsule;
	onClick: (capsule: ClassicCapsule) => void;
}

const StyledMediaContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: ${(props) => 8 / props.theme.fontSizeBase}rem 0;
	cursor: pointer;
`;

const StyledImageContainer = styled.div`
	width: ${(props) => 72 / props.theme.fontSizeBase}rem;
	height: ${(props) => 72 / props.theme.fontSizeBase}rem;
	position: relative;

	img {
		object-fit: cover;
	}
`;

const StyledTextContainer = styled.div`
	margin-left: ${(props) => 24 / props.theme.fontSizeBase}rem;
	max-width: 60%;
`;

const StyledName = styled.span`
	display: block;
	font-weight: 900;
	font-size: ${(props) => 16 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;
	margin-bottom: ${(props) => 4 / props.theme.fontSizeBase}rem;
`;

const StyledDuration = styled.span`
	display: block;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	opacity: 0.4;
`;

const StyledIconContainer = styled.div`
	width: ${(props) => 32 / props.theme.fontSizeBase}rem;
	height: ${(props) => 32 / props.theme.fontSizeBase}rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
`;

export const ClassicExperienceItem: FunctionComponent<Props> = ({
	experienceSlug, capsule, onClick,
}: Props) => {
	const currentExperienceSlug = PlayerStore.useState((s) => s.currentExperienceSlug);
	const currentCapsuleSlug = PlayerStore.useState((s) => s.currentCapsuleSlug);

	return (
		<StyledMediaContainer onClick={() => onClick(capsule)}>
			<StyledImageContainer>
				{ currentExperienceSlug === experienceSlug && currentCapsuleSlug === capsule.attributes.slug
					&& <IsPlayedCover/> }

				<Image
					src={capsule.attributes.image.data.attributes.url}
					width={72} height={72}
					alt={capsule.attributes.name}
				/>
			</StyledImageContainer>

			<StyledTextContainer>
				<StyledName>{ capsule.attributes.name }</StyledName>
				<StyledDuration>{ capsule.attributes.duration }</StyledDuration>
			</StyledTextContainer>

			<StyledIconContainer>
				{ currentCapsuleSlug === capsule.attributes.slug && <IconPause
					width={24} height={24} color={Theme.orange}/>}
				{ currentCapsuleSlug !== capsule.attributes.slug && <IconPlay
					width={24} height={24} color={Theme.orange}/>}
			</StyledIconContainer>
		</StyledMediaContainer>
	);
};
