import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { ExternalLink } from '../ExternalLink';

const StyledLink = styled.p`
	font-size: ${(props) => 11 / props.theme.fontSizeBase}rem;
	cursor: pointer;
`;

declare const openAxeptioCookies: () => void;

const CookieLink = () => {
	const { t } = useTranslation(['common']);

	return	(
		<StyledLink>
			<ExternalLink
				href={undefined}
				className="axeptio-link"
				target={null}
				onClick={() => {
					if (typeof openAxeptioCookies !== 'undefined') {
						openAxeptioCookies();
					}
				}}>
				{ t('common:footerCookies') }
			</ExternalLink>
		</StyledLink>
	);
};

export default CookieLink;
