import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import Select, {
	CSSObjectWithLabel, ControlProps, DropdownIndicatorProps, GroupBase, OptionProps,
	StylesConfig, components,
} from 'react-select';
import styled from 'styled-components';
import { IconCheckSVG } from '../map/svg/IconCheckSVG';
import { IconFlagFrance } from '../icon/IconFlagFrance';
import { IconFlagUK } from '../icon/IconFlagUK';
import Theme from '../../styles/Theme';
import { IconChevronDown } from '../icon/IconChevronDown';
import { IconChevronUp } from '../icon/IconChevronUp';

interface DropdownLangProps {
	fontSize: number;
	padding: number;
	marginBottom: number;
}

interface Lang {
	value: string;
	label: string;
}

const langs: Lang[] = [
	{ value: 'fr', label: 'Français' },
	{ value: 'en', label: 'English' },
];

const StyledLangOption = styled.div`
	display: flex;
	height: ${(props) => 40 / props.theme.fontSizeBase}rem;
	width: 100%;
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-weight: 600;
	font-size: ${(props) => 16 / props.theme.fontSizeBase}rem;
	align-items: center;
	margin: ${(props) => 8 / props.theme.fontSizeBase}rem 0 ${(props) => 6 / props.theme.fontSizeBase}rem 0;
	padding: 0 ${(props) => 16 / props.theme.fontSizeBase}rem;
	cursor: pointer;
`;

const StyledFlag = styled.span`
	margin-right: ${(props) => 16 / props.theme.fontSizeBase}rem;
	height: 100%;
	display: flex;
	align-items: center;
	jusify-content: center;
`;

const defaultWidth = 150;
const withOfLoading = 20;
const newWidth = defaultWidth + withOfLoading;

const CustomOption = (props: OptionProps<Lang>) => (
	<StyledLangOption>
		{props.data.value === 'fr' && <StyledFlag className="flag"><IconFlagFrance width={24} height={24} /></StyledFlag>}
		{props.data.value === 'en' && <StyledFlag className="flag"><IconFlagUK width={24} height={24} /></StyledFlag>}
		<components.Option {...props} />
		{props.isSelected && <IconCheckSVG width={42} height={42} color={Theme.orange}/>}
	</StyledLangOption>
);

const CustomControl = ({ children, ...props }: ControlProps<Lang>) => (
	<components.Control {...props}>
		{props.getValue().length > 0 && <>
			{props.getValue()[0].value === 'fr' && <StyledFlag className="flag"><IconFlagFrance width={24} height={24} /></StyledFlag>}
			{props.getValue()[0].value 	=== 'en' && <StyledFlag className="flag"><IconFlagUK width={24} height={24} /></StyledFlag>}
		</> }
		{children}
	</components.Control>
);

const CustomDownIndicator = ({ selectProps }: DropdownIndicatorProps<Lang>) => {
	if (selectProps.menuIsOpen) {
		return (
			<IconChevronUp width={24} height={24} color={Theme.black3} />
		);
	}

	return (
		<IconChevronDown width={24} height={24} color={Theme.black3} />
	);
};

export const DropdownLang = ({ fontSize, padding, marginBottom }: DropdownLangProps) => {
	const router = useRouter();
	const { pathname, asPath, query } = useRouter();
	const { locale } = useRouter();
	const [lang, setLang] = useState<Lang>();
	const [loading, setLoading] = useState(false);

	const [styles, setStyles] = useState<StylesConfig<Lang, false, GroupBase<Lang>>>();

	useEffect(() => {
		if (fontSize > 0 && padding !== undefined && marginBottom !== undefined) {
			setStyles({
				control: (base: CSSObjectWithLabel) => ({
					...base,
					height:       40,
					minHeight:    40,
					fontFamily:   '\'Plus Jakarta Sans\', sans-serif',
					fontSize,
					fontWeight:   600,
					width:        'fit-content',
					paddingLeft:  padding,
					paddingRight: padding,
					cursor:       'pointer',
				}),
				singleValue: (base: CSSObjectWithLabel) => ({
					...base,
					fontSize,
					cursor:      'pointer',
					marginRight: `${16 / Theme.fontSizeBase}rem`,
				}),
				option: (base: CSSObjectWithLabel) => ({
					...base,
					cursor: 'pointer',
				}),
				menuList: (base: CSSObjectWithLabel) => ({
					...base,
					width:        newWidth,
					// height:       108,
					boxShadow:    '0px 3px 10px rgba(0, 0, 0, 0.15)',
					borderRadius: 10,
					marginBottom,
					background:   'white',
				}),
			});
		}
	}, [fontSize, marginBottom, padding]);

	useEffect(() => {
		if (locale) {
			setLang(langs.find((l) => l.value === locale));
		}
	}, [locale]);

	const selectLang = useCallback(async (option: Lang | null) => {
		if (!option) return;

		setLoading(true);
		await router.push({ pathname, query }, asPath, { locale: option.value });
		setLang(option);
		setLoading(false);
	}, [asPath, pathname, query, router]);

	if (!styles) {
		return null;
	}

	return (
		<Select<Lang>
			options={langs}
			unstyled
			isSearchable={false}
			onChange={selectLang}
			isLoading={!lang || loading}
			value={lang}
			menuPlacement="top"
			styles={styles}
			components={{
				Option:            CustomOption,
				Control:           CustomControl,
				DropdownIndicator: CustomDownIndicator,
			}}
		/>
	);
};
