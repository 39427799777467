import { useCallback } from 'react';
import useWebShare from 'react-use-web-share';
import styled, { css } from 'styled-components';

import { IconShare } from '@/components/icon/IconShare';
import { ArrowGoBackButton } from '@/components/common/button/ArrowGoBackButton';
import { gtmEventSocial } from '@/utils/gtm';
import { useClient } from '@/components/context/ClientContext';
import Theme from '../../../styles/Theme';
import { Device } from '../../../custom/useDevice';
import { useSreenWidth } from '../../../custom/useScreenWidth';
import { ClassicExperience } from '../../../models/ClassicExperience';
import { Logo } from '../../common/Logo';

interface Props {
	device: Device;
	experience: ClassicExperience;
	onClick?: (experienceId: number) => void;
	onBack?: () => void;
}

const StyledExperienceCardDesktop = styled.header<{ url: string, imageHeight: number }>`
	position: relative;
	background: url('${(props) => props.url}');
	width: 100%;
	height: ${(props) => props.imageHeight}px;
	background-repeat: no-repeat;
	background-size: cover;
`;

const StyledEffect = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 100%);
	opacity: 0.56;
`;

const StyledHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: ${(props) => 72 / props.theme.fontSizeBase}rem;
`;

const StyledContent = styled.div<{ device: Device }>`
	${(props) => props.device === Device.DESKTOP && css`
		width: 70%;
	`}
	${(props) => (props.device === Device.TABLET_LANDSCAPE || props.device === Device.TABLET_PORTRAIT) && css`
		width: 85%;
	`}

	height: 100%;
	margin: 0 auto;
	padding-bottom: ${(props) => 24 / props.theme.fontSizeBase}rem;

	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
`;

const StyledTitle = styled.span`
	font-weight: 900;
	font-size: ${(props) => 28 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 32 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.white};
	margin: 0 0 ${(props) => 8 / props.theme.fontSizeBase}rem 0;
`;

const StyledNumbers = styled.span`
	font-weight: bold;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.white};
	margin: 0 0 ${(props) => 16 / props.theme.fontSizeBase}rem 0;
`;

const StyledDuration = styled.div`
	padding: ${(props) => 4 / props.theme.fontSizeBase}rem ${(props) => 6 / props.theme.fontSizeBase}rem;
	background: ${(props) => props.theme.orange};
	color: ${(props) => props.theme.white};
	border-radius: ${(props) => 2 / props.theme.fontSizeBase}rem;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-weight: bold;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
`;

const StyledShareContainer = styled.div`
	position: absolute;
	right: 0;
	bottom: ${(props) => 24 / props.theme.fontSizeBase}rem;
	display: flex;
	justify-content: center;
	align-items: center;

	width: ${(props) => 48 / props.theme.fontSizeBase}rem;
	height: ${(props) => 48 / props.theme.fontSizeBase}rem;
	border-radius: ${(props) => 48 / props.theme.fontSizeBase}rem;
	background: rgba(37, 38, 32, 0.7);
	cursor: pointer;
`;

export const ClassicExperienceCardHeaderDesktop = ({
	experience, onClick, device, onBack,
}: Props) => {
	const screenWidth = useSreenWidth();
	const imageWidth = screenWidth;
	const imageHeight = (imageWidth * 250) / 1440; // ratio de 1440 x 250

	const { client } = useClient();

	// SHARE BUTTON
	const { isSupported, share } = useWebShare();
	const onShare = useCallback(() => {
		if (experience && isSupported) {
			gtmEventSocial({
				event_group: 'social',
				event:       'partage',
				client,
			});
			share({
				title: experience.attributes.descriptionTitle,
				text:  experience.attributes.descriptionText,
				url:   `/parcours/${experience.id}`,
			});
		}
	}, [experience, isSupported, share, client]);

	return (
		<>
			<StyledHeader>
				{ onBack && <ArrowGoBackButton onClick={onBack}/> }

				<Logo/>
			</StyledHeader>

			<StyledExperienceCardDesktop
				imageHeight={imageHeight}
				url={experience.attributes.image.data.attributes.url}
				onClick={onClick ? () => onClick(experience.id) : undefined}
			>
				<StyledEffect/>

				<StyledContent device={device}>
					<StyledTitle>{ experience.attributes.descriptionTitle }</StyledTitle>

					<StyledNumbers>
						{ experience.attributes.subTitle }
					</StyledNumbers>

					<StyledDuration>
						{ experience.attributes.duration } min
					</StyledDuration>

					<StyledShareContainer onClick={onShare}>
						{ isSupported && <IconShare
							width={24}
							height={24}
							color={Theme.white}
							onClick={onShare}
						/> }
					</StyledShareContainer>
				</StyledContent>
			</StyledExperienceCardDesktop>
		</>
	);
};
