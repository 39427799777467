import { useCallback } from 'react';
import useWebShare from 'react-use-web-share';
import styled from 'styled-components';

import { IconShare } from '@/components/icon/IconShare';
import { gtmEventSocial } from '@/utils/gtm';
import { useClient } from '@/components/context/ClientContext';
import Theme from '../../../styles/Theme';
import { useSreenWidth } from '../../../custom/useScreenWidth';
import { ClassicExperience } from '../../../models/ClassicExperience';
import { IconChevronLeft } from '../../icon/IconChevronLeft';

interface Props {
	experience: ClassicExperience;
	onClick?: (experienceId: number) => void;
	onBack?: () => void;
}

const StyledExperienceCard = styled.header<{ url: string, imageHeight: number }>`
	position: relative;
	background: url('${(props) => props.url}');
	width: 100%;
	height: ${(props) => props.imageHeight}px;
	background-repeat: no-repeat;
	background-size: cover;
`;

const StyledEffect = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 100%);
	opacity: 0.56;
`;

const StyledTitle = styled.span`
	position: absolute;
	font-weight: 900;
	font-size: ${(props) => 20 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 22 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.white};
	left: ${(props) => 20 / props.theme.fontSizeBase}rem;
	bottom: ${(props) => 44 / props.theme.fontSizeBase}rem;
`;

const StyledDuration = styled.div`
	position: absolute;
	right: ${(props) => 20 / props.theme.fontSizeBase}rem;
	bottom: ${(props) => 20 / props.theme.fontSizeBase}rem;
	padding: ${(props) => 4 / props.theme.fontSizeBase}rem ${(props) => 6 / props.theme.fontSizeBase}rem;
	background: ${(props) => props.theme.white};
	border-radius: ${(props) => 2 / props.theme.fontSizeBase}rem;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-weight: bold;
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
`;

const StyledNumbers = styled.span`
	position: absolute;
	bottom: ${(props) => 20 / props.theme.fontSizeBase}rem;
	left: ${(props) => 20 / props.theme.fontSizeBase}rem;
	font-weight: bold;
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.white};
`;

const StyledButtons = styled.div`
	position: absolute;
	width: 100%;
	top: ${(props) => 8 / props.theme.fontSizeBase}rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 ${(props) => 20 / props.theme.fontSizeBase}rem;
`;

const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: ${(props) => 48 / props.theme.fontSizeBase}rem;
	height: ${(props) => 48 / props.theme.fontSizeBase}rem;
	border-radius: ${(props) => 48 / props.theme.fontSizeBase}rem;
	background: transparent;
`;

export const ClassicExperienceCardHeader = ({ experience, onClick, onBack }: Props) => {
	const screenWidth = useSreenWidth();
	const imageWidth = screenWidth;
	const imageHeight = (imageWidth * 176) / 375; // ratio de 375 x 176

	const { client } = useClient();

	// SHARE BUTTON
	const { isSupported, share } = useWebShare();
	const onShare = useCallback(() => {
		if (experience && isSupported) {
			gtmEventSocial({
				event_group: 'social',
				event:       'partage',
				client,
			});
			share({
				title: experience.attributes.descriptionTitle,
				text:  experience.attributes.descriptionText,
				url:   `/parcours/${experience.id}`,
			});
		}
	}, [experience, isSupported, share, client]);

	return (
		<StyledExperienceCard
			imageHeight={imageHeight}
			url={experience.attributes.image.data.attributes.url}
			onClick={onClick ? () => onClick(experience.id) : undefined}
		>
			<StyledEffect/>

			<StyledButtons>
				{ onBack && <StyledButtonContainer onClick={onBack}>
					<IconChevronLeft width={24} height={24} color={Theme.white}/>
				</StyledButtonContainer> }
				<StyledButtonContainer onClick={onShare}>
					{ !isSupported && <IconShare
						width={24}
						height={24}
						color={Theme.white}
						onClick={onShare}
					/> }
				</StyledButtonContainer>
			</StyledButtons>

			<StyledTitle>{ experience.attributes.descriptionTitle }</StyledTitle>

			<StyledNumbers>
				{ experience.attributes.subTitle }
			</StyledNumbers>

			<StyledDuration>
				{ experience.attributes.duration } min
			</StyledDuration>
		</StyledExperienceCard>
	);
};
