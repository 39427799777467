import Image from 'next/image';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import Theme from '../../../styles/Theme';
import { Device, useDevice } from '../../../custom/useDevice';
import { useSreenWidth } from '../../../custom/useScreenWidth';
import { ClassicCapsule } from '../../../models/ClassicCapsule';
import { IconPlaySVG } from '../../map/svg/IconPlaySVG';

interface Props {
	capsule: ClassicCapsule;

	totalMedia: number;
	index: number;

	onClick: (capsule: ClassicCapsule) => void;
}

const StyledExperienceItemMosaic = styled.div<{ w: number }>`
	position: relative;
	cursor: pointer;
	max-width: ${(props) => props.w}px;
`;

const StyledMediaContainer = styled.div<{ w: number }>`
	border-radius: ${(props) => 8 / props.theme.fontSizeBase}rem;
	width: ${(props) => props.w}px;
	height: ${(props) => props.w}px;

	img {
		object-fit: cover;
		border-radius: ${(props) => 8 / props.theme.fontSizeBase}rem;
	}
`;

const StyledTextContainer = styled.div<{ hasMarginBottom: boolean }>`
	margin: ${(props) => 12 / props.theme.fontSizeBase}rem 0 0 ${(props) => 8 / props.theme.fontSizeBase}rem;

	${(props) => props.hasMarginBottom && css`
		margin: ${12 / props.theme.fontSizeBase}rem 0 ${24 / props.theme.fontSizeBase}rem ${8 / props.theme.fontSizeBase}rem;
	`}
`;

const StyledName = styled.span`
	display: block;
	font-weight: 900;
	font-size: ${(props) => 16 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;
	margin-bottom: ${(props) => 4 / props.theme.fontSizeBase}rem;
`;

const StyledDuration = styled.span`
	display: block;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	opacity: 0.56;
`;

const StyledVideoContainer = styled.div<{ w: number }>`
	border-radius: ${(props) => 8 / props.theme.fontSizeBase}rem;
	position: absolute;
	width: ${(props) => props.w}px;
	height: ${(props) => props.w}px;
	top: 0;
	left: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 100%);
	opacity: 0.56;
	z-index: 1;

	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledPlayRoundContainer = styled.div<{ w: number }>`
	position: absolute;
	top: 0;
	left: 0;
	width: ${(props) => props.w}px;
	height: ${(props) => props.w}px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
`;

const StyledPlayRoundIconContainer = styled.div<{w: number}>`
	width: ${(props) => props.w}px;
	height: ${(props) => props.w}px;
	display: flex;
    align-items: center;
	justify-content: center;
	background: ${(props) => props.theme.white};
	border-radius: ${(props) => props.w}px;
`;

export const ClassicExperienceItemMosaic: FunctionComponent<Props> = ({
	capsule, index, totalMedia, onClick,
}: Props) => {
	const device = useDevice();
	const screenWidth = useSreenWidth();
	const width = device === Device.MOBILE
		? Math.round((screenWidth - (20 * 2) - 18) / 2)
		: Math.round((screenWidth * 159) / 1440);
	const iconWith = width < 64 * 2 ? 32 / 2 : 32; // To support small screens (like portrait tablets)

	return (
		<StyledExperienceItemMosaic w={width} onClick={() => onClick(capsule)}>
			{ capsule.attributes.image.data
			&& <>
				<StyledVideoContainer w={width}/>
				<StyledPlayRoundContainer w={width}>
					<StyledPlayRoundIconContainer w={iconWith * 2}>
						<IconPlaySVG width={iconWith} height={iconWith} color={Theme.textPrimary}/>
					</StyledPlayRoundIconContainer>
				</StyledPlayRoundContainer>
			</> }

			<StyledMediaContainer w={width}>
				{ capsule.attributes.image.data && <Image
					alt={capsule.attributes.name}
					width={width}
					height={width}
					src={capsule.attributes.image.data.attributes.url}
				/> }
			</StyledMediaContainer>

			<StyledTextContainer hasMarginBottom={index < totalMedia - 2}>
				<StyledName>{ capsule.attributes.name }</StyledName>
				<StyledDuration>{ capsule.attributes.duration === '0' ? '' : capsule.attributes.duration }</StyledDuration>
			</StyledTextContainer>
		</StyledExperienceItemMosaic>
	);
};
