import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconFlagFrance = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1001_18061)">
			<path d="M12.7508 24.5684C19.3783 24.5684 24.7508 19.1958 24.7508 12.5684C24.7508 5.94094 19.3783 0.568359 12.7508 0.568359C6.12342 0.568359 0.750839 5.94094 0.750839 12.5684C0.750839 19.1958 6.12342 24.5684 12.7508 24.5684Z" fill="#F0F0F0"/>
			<path d="M24.7508 12.5686C24.7508 7.40897 21.4943 3.01046 16.9247 1.31494V23.8222C21.4943 22.1266 24.7508 17.7281 24.7508 12.5686Z" fill="#D80027"/>
			<path d="M0.750839 12.5686C0.750839 17.7281 4.00739 22.1266 8.57695 23.8222V1.31494C4.00739 3.01046 0.750839 7.40897 0.750839 12.5686Z" fill="#0052B4"/>
		</g>
		<defs>
			<clipPath id="clip0_1001_18061">
				<rect width="24" height="24" fill="white" transform="translate(0.750839 0.568359)"/>
			</clipPath>
		</defs>
	</svg>

));
