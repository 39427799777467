import { FunctionComponent, HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
	className?: string;
	href?: string;
	onClick?: () => void;
	target?: HTMLAttributeAnchorTarget | null;
}

const StyledExternalLink = styled.a`
	color: ${(props) => props.theme.textPrimary};
	text-decoration: none;
`;

export const ExternalLink: FunctionComponent<PropsWithChildren<Props>> = ({
	href, className, children, onClick, target,
}: PropsWithChildren<Props>) => (
	<StyledExternalLink
		className={className}
		href={href}
		target={target === null ? undefined : target}
		onClick={onClick}>
		{ children }
	</StyledExternalLink>
);
