import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconWaves = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M26.6667 8H29.3334V28H26.6667V8Z" fill={props.color}/>
		<path d="M21.3334 16H18.6667V28H21.3334V16Z" fill={props.color}/>
		<path d="M13.3334 4H10.6667V28H13.3334V4Z" fill={props.color}/>
		<path d="M5.33341 12H2.66675V28H5.33341V12Z" fill={props.color}/>
	</svg>
));
