import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import Theme from '../../../styles/Theme';
import { IconPlay } from '../../icon/IconPlay';

interface Props {
	className?: string;
	onClick: () => void;
}

const StyledPrimaryButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: ${(props) => 20 / props.theme.fontSizeBase}rem 0;

	width: 100%;
	background: ${(props) => props.theme.btnPrimary};
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	border-radius: 300px;
`;

const StyledText = styled.span`
	font-family: Lato;
	font-size: ${(props) => 16 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.white};
	margin: 0 ${(props) => 14 / props.theme.fontSizeBase}rem;
`;

const PrimaryButton = ({ className, children, onClick }: PropsWithChildren<Props>) => (
	<StyledPrimaryButton className={className} onClick={onClick}>
		<IconPlay width={24} height={24} color={Theme.white}/>
		<StyledText>{ children }</StyledText>
	</StyledPrimaryButton>
);

export default PrimaryButton;
