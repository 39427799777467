import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconChevronUp = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path transform="scale (1, -1)" transform-origin="center" d="M11.1556 16.4234C11.5355 16.0533 12.1052 15.8683 12.4851 15.4983C11.7254 15.1282 11.1556 14.3881 10.5859 13.833C10.0161 13.278 9.25644 12.5379 8.68668 11.9828C7.35724 10.8727 6.0278 9.76252 5.0782 8.0973C4.50844 7.17218 2.98908 8.0973 3.36892 9.20745C4.1286 10.8727 5.83788 11.7978 7.16732 12.9079C8.68668 14.2031 9.8262 15.8683 11.5355 16.9785C11.9153 17.1635 12.4851 16.9785 12.675 16.7934C15.144 14.2031 17.6129 11.7978 20.0819 9.20745C20.8416 8.46735 19.5121 7.17218 18.7524 7.91228C16.2835 10.5026 13.8145 12.9079 11.3456 15.4983C11.7254 15.4983 12.1052 15.3132 12.4851 15.3132C11.7254 14.7582 11.1556 14.0181 10.396 13.463C9.8262 12.9079 9.25644 12.3529 8.49676 11.7978C7.16732 10.8727 5.64796 9.94754 5.0782 8.28233C4.50844 8.46735 3.93868 8.8374 3.36892 9.20745C4.50844 10.8727 5.83788 12.1678 7.35724 13.463C8.11692 14.0181 8.68668 14.5731 9.44636 15.3132C10.0161 15.8683 10.5859 16.7934 11.5355 17.1635C12.1052 17.5335 12.8649 17.1635 13.0548 16.4234C12.8087 16.4234 11.1556 16.4234 11.1556 16.4234Z" fill={props.color}/>
	</svg>
));
