import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
	className?: string;
}

const StyledSeparator = styled.div`
	width: 100%;
	height: 1px;
	background-color: #AEAEB4;
	opacity: 0.24;
`;

export const Separator: FunctionComponent<Props> = ({ className }: Props) => (
	<StyledSeparator className={className} />
);
