// import { IconPlay } from '@/components/icon/IconPlay';
import { Device, useDevice } from '@/custom/useDevice';
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface Props extends PropsWithChildren {
	url: string;
	logo?: string;
}

// TODO: If client without Image you need to add a condition for the padding.

const StyledBackButton = styled.a<{ $device: Device }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	// padding: ${(props) => 20 / props.theme.fontSizeBase}rem 0;

	width: 100%;
	background: ${(props) => props.theme.white};
	// border: 1px solid ${(props) => props.theme.grey};
	box-sizing: border-box;
	cursor: pointer;
	border-radius: 300px;

	text-decoration: none;
	margin-top: ${(props) => 16 / props.theme.fontSizeBase}rem;

	${(props) => props.$device !== Device.MOBILE && css`
		margin-top: ${16 / props.theme.fontSizeBase}rem;
		width: ${268 / props.theme.fontSizeBase}rem;
	`}

	padding: 0;

	img {
		height: 64px;
		max-width: 100%;
		max-height: 100%;
	}
`;

const StyledText = styled.span`
	font-family: Lato;
	font-size: ${(props) => 16 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.btnPrimary};
	margin: 0 ${(props) => 14 / props.theme.fontSizeBase}rem;
`;

const BackButton = ({ url, logo, children }: Props) => {
	const device = useDevice();

	return (
		<StyledBackButton href={url} $device={device}>
			{ logo && <img src={logo} alt="bouton retour logo" /> }
			{ !logo && <StyledText>{ children }</StyledText> }
		</StyledBackButton>
	);
};

export default BackButton;
