import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconFlagUK = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1001_18065)">
			<path d="M12.7509 24.5684C19.3783 24.5684 24.7509 19.1958 24.7509 12.5684C24.7509 5.94094 19.3783 0.568359 12.7509 0.568359C6.12345 0.568359 0.750872 5.94094 0.750872 12.5684C0.750872 19.1958 6.12345 24.5684 12.7509 24.5684Z" fill="#F0F0F0"/>
			<path d="M3.2315 5.2627C2.28889 6.48909 1.57812 7.9026 1.16426 9.43818H7.40698L3.2315 5.2627Z" fill="#0052B4"/>
			<path d="M24.3375 9.43813C23.9236 7.9026 23.2128 6.48909 22.2702 5.2627L18.0949 9.43813H24.3375Z" fill="#0052B4"/>
			<path d="M1.16426 15.6992C1.57817 17.2348 2.28893 18.6483 3.2315 19.8746L7.40684 15.6992H1.16426Z" fill="#0052B4"/>
			<path d="M20.0567 3.04921C18.8303 2.10661 17.4169 1.39584 15.8813 0.981934V7.22461L20.0567 3.04921Z" fill="#0052B4"/>
			<path d="M5.44502 22.0879C6.67141 23.0306 8.08492 23.7413 9.62045 24.1552V17.9126L5.44502 22.0879Z" fill="#0052B4"/>
			<path d="M9.62041 0.981934C8.08488 1.39584 6.67136 2.10661 5.44502 3.04917L9.62041 7.22456V0.981934Z" fill="#0052B4"/>
			<path d="M15.8813 24.1552C17.4169 23.7413 18.8304 23.0306 20.0567 22.088L15.8813 17.9126V24.1552Z" fill="#0052B4"/>
			<path d="M18.0949 15.6992L22.2702 19.8747C23.2128 18.6483 23.9236 17.2348 24.3375 15.6992H18.0949Z" fill="#0052B4"/>
			<path d="M24.6493 11.0032H14.3162H14.3161V0.669938C13.8037 0.603234 13.2814 0.568359 12.7509 0.568359C12.2203 0.568359 11.698 0.603234 11.1857 0.669938V11.0031V11.0031H0.85245C0.785747 11.5155 0.750872 12.0379 0.750872 12.5684C0.750872 13.0989 0.785747 13.6212 0.85245 14.1336H11.1856H11.1856V24.4668C11.698 24.5335 12.2203 24.5684 12.7509 24.5684C13.2814 24.5684 13.8037 24.5335 14.3161 24.4668V14.1337V14.1336H24.6493C24.716 13.6212 24.7509 13.0989 24.7509 12.5684C24.7509 12.0379 24.716 11.5155 24.6493 11.0032Z" fill="#D80027"/>
			<path d="M15.8813 15.6988L21.2361 21.0536C21.4824 20.8074 21.7174 20.55 21.9415 20.2832L17.3571 15.6987H15.8813V15.6988Z" fill="#D80027"/>
			<path d="M9.62042 15.6987H9.62032L4.26556 21.0535C4.51175 21.2998 4.76914 21.5347 5.03595 21.7589L9.62042 17.1743V15.6987Z" fill="#D80027"/>
			<path d="M9.62042 9.43796V9.43787L4.26561 4.08301C4.01933 4.3292 3.78439 4.58659 3.56024 4.8534L8.14475 9.43791H9.62042V9.43796Z" fill="#D80027"/>
			<path d="M15.8813 9.43816L21.2362 4.08326C20.99 3.83698 20.7326 3.60204 20.4658 3.37793L15.8813 7.96245V9.43816Z" fill="#D80027"/>
		</g>
		<defs>
			<clipPath id="clip0_1001_18065">
				<rect width="24" height="24" fill="white" transform="translate(0.750839 0.568359)"/>
			</clipPath>
		</defs>
	</svg>

));
