/* eslint-disable max-len */
import useSWR from 'swr';

import qs from 'qs';
import { httpGet } from '../lib/fetch';
import { ClassicExperience } from '../models/ClassicExperience';

import { StrapiGet } from './Strapi';

export function useClassicExperiencesFromClientId(clientId: number, locale: string) {
	const query = qs.stringify({
		filters: {
			client: {
				id: {
					$eq: clientId,
				},
			},
		},
		populate: 'image',
		sort:     'position',
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<ClassicExperience[] | null>(
		[`/classic-experiences?${query}`, locale],
		async ([url, localeSWR]) => {
			const data = await httpGet<StrapiGet<ClassicExperience[]>>(url);

			const classicExperiences = data.data;

			classicExperiences.map((classicExperience) => {
				if (localeSWR === 'en') {
					if (classicExperience.attributes.titleEn && classicExperience.attributes.titleEn.length > 0) {
						classicExperience.attributes.title = classicExperience.attributes.titleEn;
					}
					if (classicExperience.attributes.subTitleEn && classicExperience.attributes.subTitleEn.length > 0) {
						classicExperience.attributes.subTitle = classicExperience.attributes.subTitleEn;
					}
					if (classicExperience.attributes.descriptionTitleEn && classicExperience.attributes.descriptionTitleEn.length > 0) {
						classicExperience.attributes.descriptionTitle = classicExperience.attributes.descriptionTitleEn;
					}
					if (classicExperience.attributes.descriptionTextEn && classicExperience.attributes.descriptionTextEn.length > 0) {
						classicExperience.attributes.descriptionText = classicExperience.attributes.descriptionTextEn;
					}
				}
				return classicExperience;
			});

			return classicExperiences;
		},
	);
}

export function useClassicExperience(experienceSlug: string, locale: string) {
	const query = qs.stringify({
		filters: {
			slug: {
				$eq: experienceSlug,
			},
		},
		populate: 'image',
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<ClassicExperience | null>(
		[`/classic-experiences?${query}`, locale],
		async ([url, localeSWR]) => {
			const data = await httpGet<StrapiGet<ClassicExperience[]>>(url);

			if (data.data.length === 0) {
				return null;
			}

			const classicExperience = data.data[0];

			if (localeSWR === 'en') {
				if (classicExperience.attributes.titleEn && classicExperience.attributes.titleEn.length > 0) {
					classicExperience.attributes.title = classicExperience.attributes.titleEn;
				}
				if (classicExperience.attributes.subTitleEn && classicExperience.attributes.subTitleEn.length > 0) {
					classicExperience.attributes.subTitle = classicExperience.attributes.subTitleEn;
				}
				if (classicExperience.attributes.descriptionTitleEn && classicExperience.attributes.descriptionTitleEn.length > 0) {
					classicExperience.attributes.descriptionTitle = classicExperience.attributes.descriptionTitleEn;
				}
				if (classicExperience.attributes.descriptionTextEn && classicExperience.attributes.descriptionTextEn.length > 0) {
					classicExperience.attributes.descriptionText = classicExperience.attributes.descriptionTextEn;
				}
			}
			return classicExperience;
		},
	);
}
