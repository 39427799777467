import { FunctionComponent, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useTranslation } from 'next-i18next';
import { gtmEventMusair } from '@/utils/gtm';
import { useRouter } from 'next/router';
import { useClient } from '@/components/context/ClientContext';
import { Device, useDevice } from '../../../custom/useDevice';
import { PlayerStore } from '../../../data/PlayerStore';
import { DomainEnum } from '../../../utils/domains';
import { ExternalLink } from '../ExternalLink';
import { Logo } from '../../common/Logo';
import { Separator } from './Separator';
import { DropdownLang } from '../../common/DropdownLang';
import CookieLink from './CookieLink';
import Year from './Year';

interface Props {
	domainEnum: DomainEnum;
	device: Device;
	translation: boolean;
}

const StyledFooter = styled.div<{audio: boolean}>`
	padding-left: ${(props) => 20 / props.theme.fontSizeBase}rem;
	padding-right: ${(props) => 20 / props.theme.fontSizeBase}rem;
	padding-bottom: ${(props) => 28 / props.theme.fontSizeBase}rem;

	${(props) => props.audio && css`
		margin: 0 ${20 / props.theme.fontSizeBase}rem ${48 / props.theme.fontSizeBase}rem ${20 / props.theme.fontSizeBase}rem;
	`}

	p {
		color: #3F4234;
		font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
		font-weight: 400;
		line-height: ${(props) => 24 / props.theme.fontSizeBase}rem;
	}
`;

const StyledFooterTexts = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => 8 / props.theme.fontSizeBase}rem;
	padding: ${(props) => 16 / props.theme.fontSizeBase}rem 0;
`;

const StyledFooterDesktopContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: ${(props) => 64 / props.theme.fontSizeBase}rem;
	height: ${(props) => 56 / props.theme.fontSizeBase}rem;
	background: #F9F9F9;
`;

const StyledFooterDesktop = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: ${(props) => 8 / props.theme.fontSizeBase}rem;

	p {
		color: #3F4234;
		font-size: ${(props) => 11 / props.theme.fontSizeBase}rem;
		font-weight: 700;
	}
`;

const StyledLogo = styled(Logo)`
	margin-top: ${(props) => 24 / props.theme.fontSizeBase}rem;
	margin-bottom: ${(props) => 8 / props.theme.fontSizeBase}rem;
	text-align: center;
`;

const StyledLangContainer = styled.div<{noMargin?: boolean}>`
	margin-bottom: ${(props) => 8 / props.theme.fontSizeBase}rem;

	${(props) => props.noMargin && css`
		margin-bottom: 0;
	`};

	.flag {
		margin-right: ${(props) => 12 / props.theme.fontSizeBase}rem;
	}
`;

const ClassicFooter: FunctionComponent<Props> = ({
	translation = true,
}: Props) => {
	const { locale } = useRouter();

	const { t } = useTranslation(['common']);
	const device = useDevice();
	const audio = PlayerStore.useState((s) => s.audio);
	const { client } = useClient();

	const onCredit = useCallback(() => {
		// createEvent({
		// 	action: 'credits',
		// });
		gtmEventMusair({
			event:       'credits',
			event_group: 'musair',
			client,
		});
	}, [client]);

	return (
		<>
			{ device === Device.MOBILE && <StyledFooter audio={!!audio}>
				<Separator/>

				<StyledFooterTexts>
					<p>
						<ExternalLink href={`/${locale}/droits-et-credits`} onClick={onCredit}>{ t('common:footerCredits') }</ExternalLink>
					</p>

					<p>
						<ExternalLink href={`/${locale}/mentions-legales`}>{ t('common:footerLegalNotice') }</ExternalLink>
					</p>

					<p>
						<ExternalLink href={`/${locale}/cgu`}>{ t('common:footerCgu') }</ExternalLink>
					</p>

					<p>
						<ExternalLink href={`/${locale}/politique-de-confidentialite`}>{ t('common:footerPolicies') }</ExternalLink>
					</p>

					<CookieLink/>

					<StyledLangContainer>
						{ translation && <DropdownLang fontSize={12} padding={0} marginBottom={8} /> }
					</StyledLangContainer>
				</StyledFooterTexts>

				<Separator/>

				<StyledLogo/>
				<Year/>
			</StyledFooter>
			}

			{ device !== Device.MOBILE
			&& <StyledFooterDesktopContainer>
				<StyledFooterDesktop>
					<Year/>

					<p>
						<ExternalLink href={`/${locale}/droits-et-credits`}>{ t('common:footerCredits') }</ExternalLink>
					</p>

					<p>
						<ExternalLink href={`/${locale}/mentions-legales`}>{ t('common:footerLegalNotice') }</ExternalLink>
					</p>

					<p>
						<ExternalLink href={`/${locale}/cgu`}>{ t('common:footerCgu') }</ExternalLink>
					</p>

					<p>
						<ExternalLink href={`/${locale}/politique-de-confidentialite`}>{ t('common:footerPolicies') }</ExternalLink>
					</p>

					<CookieLink/>

					<StyledLangContainer noMargin={true}>
						{ translation && <DropdownLang fontSize={12} padding={0} marginBottom={0} /> }
					</StyledLangContainer>
				</StyledFooterDesktop>
			</StyledFooterDesktopContainer> }
		</>
	);
};

export default ClassicFooter;
