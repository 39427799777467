import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconCheckSVG = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.84693 16.4233C10.2268 16.0533 10.7965 15.8683 11.1764 15.4982C10.4167 15.1282 9.84692 14.3881 9.27716 13.833C9.7074 14.2779 9.94772 14.5378 9.37796 13.9827C8.69141 13.5 8.19141 13 7.76948 12.0972C7.19972 11.1721 5.68036 12.0972 6.0602 13.2074C6.69141 14 6.52916 13.7977 7.8586 14.9079C9.37796 16.203 8.51749 15.8683 10.2268 16.9784C10.6066 17.1634 11.1764 16.9784 11.3663 16.7934C13.8352 14.203 16.3042 11.7977 18.7732 9.20738C19.5328 8.46729 18.2034 7.17212 17.4437 7.91222C14.9748 10.5026 12.5058 12.9079 10.0368 15.4982C10.4167 15.4982 10.7965 15.3132 11.1764 15.3132C10.4167 14.7581 10.8469 15.018 10.0872 14.4629C9.51749 13.9079 9.94773 14.3528 9.18804 13.7977C7.8586 12.8726 8.33924 13.9475 7.76948 12.2823C7.19972 12.4673 6.62996 12.8373 6.0602 13.2074C7.19141 14.5 6.52916 14.1678 8.04852 15.4629C8.8082 16.018 8.37796 15.5731 9.13765 16.3132C9.70741 16.8683 9.27717 16.7934 10.2268 17.1634C10.7965 17.5335 11.5562 17.1634 11.7461 16.4233C11.5 16.4233 9.84693 16.4233 9.84693 16.4233Z" fill={props.color} />
	</svg>
));
