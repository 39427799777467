import { Device, useDevice } from '@/custom/useDevice';
import styled, { css } from 'styled-components';
import {
	FacebookShareButton,
} from 'react-share';

import { IconShare } from '@/components/icon/IconShare';
import PrimaryButton from '@/components/classic/button/PrimaryButton';

import { ClassicExperienceCardHeader } from '@/components/classic/experience/ClassicExperienceCardHeader';
import {
	ClassicExperienceCardHeaderDesktop,
} from '@/components/classic/experience/ClassicExperienceCardHeaderDesktop';
import { ClassicExperienceItem } from '@/components/classic/experience/ClassicExperienceItem';
import { ClassicExperienceItemMosaic } from '@/components/classic/mosaic/ClassicExperienceItemMosaic';
import { Spinner } from '@/components/common/Spinner';
import Theme from '@/styles/Theme';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { ClassicCapsule } from '@/models/ClassicCapsule';
import { useClassicExperience } from '@/data/ClassicExperience.swr';
import { useClassicCapsulesFromExperienceSlug } from '@/data/ClassicCapsule.swr';
import { Client } from '@/models/Client';
import { useTranslation } from 'next-i18next';
import { gtmEventSocial } from '@/utils/gtm';
import BackButton from './button/BackButton';
import { Separator } from './footer/Separator';
import { useClient } from '../context/ClientContext';

interface Props {
	host: string;
	client: Client;
	slug: string;
	onBack?: () => void;
}

const StyledParcours = styled.div`
	flex: 1;
`;

const StyledDescription = styled.div<{ device: Device }>`
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 23 / props.theme.fontSizeBase}rem;
	margin: ${(props) => 24 / props.theme.fontSizeBase}rem 0 ${(props) => 24 / props.theme.fontSizeBase}rem 0;

	a {
		color: ${(props) => props.theme.black};
		font-weight: 700;
		text-decoration: none;
	}

	${(props) => props.device !== Device.MOBILE && css`
		margin: 0 auto ${26 / props.theme.fontSizeBase}rem auto;
	`}
`;

const StyledContainer = styled.div<{ device: Device }>`
	${(props) => props.device !== Device.MOBILE && css`
		display: flex;
		justify-content: space-between;
		margin: ${43 / props.theme.fontSizeBase}rem auto 0 auto;
	`}

	${(props) => props.device === Device.DESKTOP && css`
		width: 70%;
		gap: ${64 / props.theme.fontSizeBase}rem
	`}

	${(props) => (props.device === Device.TABLET_PORTRAIT || props.device === Device.TABLET_LANDSCAPE) && css`
		width: 85%;
		gap: ${32 / props.theme.fontSizeBase}rem
	`}
`;

const StyledDescButton = styled.div<{ device: Device }>`
	margin: ${(props) => 24 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem 0 ${(props) => 20 / props.theme.fontSizeBase}rem;
	padding-bottom: ${(props) => 32 / props.theme.fontSizeBase}rem;

	${(props) => props.device !== Device.MOBILE && css`
		margin: 0;
		padding-bottom: 0;
	`}
`;

const StyledItems = styled.div<{ device: Device }>`
	margin: 0 ${(props) => 20 / props.theme.fontSizeBase}rem 0 ${(props) => 20 / props.theme.fontSizeBase}rem;

	${(props) => props.device !== Device.MOBILE && css`
		min-width: 335px;
		width: 100%;
		max-width: calc(335px * 2);
	`}
`;

const StyledPrimaryButton = styled(PrimaryButton)<{ device: Device }>`
	${(props) => props.device !== Device.MOBILE && css`
		width: ${268 / props.theme.fontSizeBase}rem;
	`}
`;

const StyledMosaic = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const StyledFacebookShareButton = styled(FacebookShareButton)`
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding: ${(props) => 15 / props.theme.fontSizeBase}rem 0 !important;
	margin-top: ${(props) => 32 / props.theme.fontSizeBase}rem;
	margin-bottom: ${(props) => 32 / props.theme.fontSizeBase}rem;

	width: 100% !important;

	border: 2px solid ${(props) => props.theme.btnLight} !important;
	border-radius: 300px;
`;

const StyledShareText = styled.span`
	color: ${(props) => props.theme.textPrimary};
	margin: 0 ${(props) => 17 / props.theme.fontSizeBase}rem;
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 700;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: 100%;
`;

const StyledIcon = styled.span<{ device: Device }>`
	display: block;
	width: ${(props) => 22 / props.theme.fontSizeBase}rem;
	height: ${(props) => 22 / props.theme.fontSizeBase}rem;
	position: relative;

	${(props) => props.device !== Device.MOBILE && css`
		@media (max-width: 1365px) {
			width: ${18 / props.theme.fontSizeBase}rem;
			height: ${18 / props.theme.fontSizeBase}rem;
		}
	`}
`;

const StyledBackButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-top: ${(props) => 16 / props.theme.fontSizeBase}rem;
	margin-bottom: ${(props) => 16 / props.theme.fontSizeBase}rem;
`;

const ParcoursSlugPage = ({
	host,
	client,
	slug,
	onBack,
}: Props) => {
	const { t } = useTranslation(['common']);
	const router = useRouter();
	const device = useDevice();
	const { client: clientContext } = useClient();

	const { data: classicExperience } = useClassicExperience(slug, router.locale || 'fr');
	const { data: classicCapsules } = useClassicCapsulesFromExperienceSlug(slug, router.locale || 'fr');

	// GO TO CAPSULE
	const goToCapsule = useCallback((capsule: ClassicCapsule) => {
		if (classicExperience && slug) {
			if (capsule.attributes.audio.data || capsule.attributes.vimeoId !== '0') {
				// createEvent({
				// 	action: capsule.attributes.analyticsEvent ? `demarrage_parcours_${capsule.attributes.analyticsEvent}` : `demarrage_parcours_${slug}_${capsule.attributes.slug}`,
				// });
				router.push(`/parcours/${slug}/capsule/${capsule.attributes.slug}`);
			}
		}
	}, [classicExperience, router, slug]);

	// GO TO FIRST CAPSULE
	const goToFirstCapsule = useCallback(() => {
		if (classicExperience && slug && classicCapsules) {
			// createEvent({
			// 	action: classicCapsules[0].attributes.analyticsEvent ? `demarrage_parcours_${classicCapsules[0].attributes.analyticsEvent}` : `demarrage_parcours_${slug}_${classicCapsules[0].attributes.slug}`,
			// });
			router.push(`/parcours/${slug}/capsule/${classicCapsules[0].attributes.slug}`);
		}
	}, [classicCapsules, classicExperience, router, slug]);

	// SHARE FB
	const beforeOnShareFb = useCallback(() => {
		// createEvent({
		// 	action:   'partage',
		// 	category: 'social',
		// });
		gtmEventSocial({
			event_group: 'social',
			event:       'partage',
			client:      clientContext,
		});
	}, [clientContext]);

	return (
		<StyledParcours>
			{ !classicExperience && <Spinner/> }
			{ classicExperience && <>
				{ device === Device.MOBILE
					? <ClassicExperienceCardHeader experience={classicExperience} onBack={onBack}/>
					: <ClassicExperienceCardHeaderDesktop
						device={device} experience={classicExperience} onBack={onBack}/>
				}

				<StyledContainer device={device}>
					<StyledDescButton device={device}>
						{ classicExperience.attributes.descriptionText
							&& classicExperience.attributes.descriptionText.length > 0
							&& <StyledDescription
								device={device}
								// lines={device === Device.MOBILE ? 3 : 0}
								// more={t('common:displayMore')}
								// less={t('common:displayLess')}
							>
								{ classicExperience.attributes.descriptionText }
							</StyledDescription> }

						{ classicCapsules && classicCapsules.length > 0
							&& <StyledPrimaryButton device={device} onClick={goToFirstCapsule}>
								{ t('common:mapStartExperience') }
							</StyledPrimaryButton> }

						{ device !== Device.MOBILE && client.attributes.backButtonUrl && client.attributes.backButtonUrl.length > 0
							&& <BackButton logo={client.attributes.backButtonLogo?.data?.attributes.url} url={client.attributes.backButtonUrl}>
								{ client.attributes.backButtonText }
							</BackButton> }
					</StyledDescButton>

					<StyledItems device={device}>
						{ !classicExperience.attributes.mosaic
							&& classicCapsules
							&& classicCapsules.map((capsule, index) => (
								<ClassicExperienceItem key={index}
									experienceSlug={classicExperience.attributes.slug}
									onClick={goToCapsule}
									capsule={capsule}
								/>
							)) }

						{ classicExperience.attributes.mosaic
								&& <StyledMosaic>
									{ classicCapsules?.map((capsule, index) => (
										<ClassicExperienceItemMosaic
											key={index}
											totalMedia={classicCapsules.length}
											index={index}
											capsule={capsule}
											onClick={goToCapsule}
										/>
									)) }
								</StyledMosaic> }

						{ device === Device.MOBILE && client.attributes.backButtonUrl && client.attributes.backButtonUrl.length > 0
							&& <StyledBackButtonContainer>
								<Separator/>
								<BackButton logo={client.attributes.backButtonLogo?.data?.attributes.url} url={client.attributes.backButtonUrl}>
									{ client.attributes.backButtonText }
								</BackButton>
							</StyledBackButtonContainer>
						}

						{ device === Device.MOBILE
							&& client.attributes.facebook
							&& client.attributes.facebook.length > 0
							&& <StyledFacebookShareButton url={`https://${host}/parcours/${slug}`} beforeOnClick={beforeOnShareFb}>
								<StyledIcon device={device}>
									<IconShare width={24} height={24} color={Theme.textPrimary}/>
								</StyledIcon>
								<StyledShareText>{ t('common:mapShare') }</StyledShareText>
							</StyledFacebookShareButton> }

					</StyledItems>
				</StyledContainer>
			</>
			}
		</StyledParcours>
	);
};

export default ParcoursSlugPage;
