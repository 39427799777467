import styled from 'styled-components';

const StyledYear = styled.p`
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	text-align: center;
`;

const Year = () => (
	<StyledYear>{ new Date().getFullYear() }©</StyledYear>
);

export default Year;
