import { FunctionComponent } from 'react';
import styled from 'styled-components';

import Theme from '../../styles/Theme';
import { IconWaves } from '../icon/IconWaves';

const StyledIsPlayed = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: ${(props) => 72 / props.theme.fontSizeBase}rem;
	height: ${(props) => 72 / props.theme.fontSizeBase}rem;
	left: 0;
	top: 0;
	z-index: 1;

	background: rgba(221, 113, 85, 0.64);
`;

export const IsPlayedCover: FunctionComponent = () => (
	<StyledIsPlayed>
		<IconWaves width={32} height={32} color={Theme.white}/>
	</StyledIsPlayed>
);
